import React, {useEffect} from 'react'; 
import {Link} from 'react-router-dom';

const ManageNavigationComponent = ({features =[], setPage, showNavigation}) => {
    const switchPage = page =>setPage(page);   
    return (
        <ul className={`left-vertical-navigation ${showNavigation? 'height-max-content': 'height-0'}`} style={{...styles.ul}}>
            {features.map((feature, id) =>(
                <li key={id}><button style={styles.button} onClick={() =>switchPage(feature)}>{feature}</button></li>
            ))}
            {showNavigation &&<li><Link to='/logout' style={{...styles.button, color: '#333'}}>Logout</Link></li>}
        </ul>
    )
}
const styles ={  
    button: {
        display: 'block',
        width: '100%',
        padding: '1rem',
        textAlign: 'left',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        
    }
}
export default ManageNavigationComponent;    