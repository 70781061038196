import React from 'react'
import { PortalNavigationComponent } from '../components';

const ChatPage = () => {
  return (
    <div style={styles.page}>
        <PortalNavigationComponent />
        <main style={styles.grid}>
            <div >
                <h2 style={styles.chatTitle}>Chats</h2>
            </div>
            <div style={styles.mainContent}>
                <h3 style={styles.recipient}>Recipient Name</h3> 
                <div style={styles.chatArea}>
                    <textarea style={styles.textarea} placeholder ="Send Message"></textarea>
                    <button style={styles.button}>Send</button>
                </div>
                <div>
                    <div  style={{...styles.chat, ...{justifyContent: 'flex-start'}}}><p style={styles.chatContent}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, magni.</p></div>
                    <div  style={{...styles.chat, ...{justifyContent: 'flex-end'}}}><p style={styles.chatContent}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, magni.</p></div>
                </div>
            </div> 
        </main>
    </div>
  )
}

const styles ={
    recipient: {
        textAlign: 'center',
        paddingBottom: '1rem',
        color: '#333',
    },
    page: {
        display: 'flex', 
        flexDirection: 'column',
        minHeight: '100vh', 
        
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '200px auto',  
    },
    mainContent:{
        backgroundColor: 'rgba(0, 0, 0, .05)',
        height: '91.25vh',
        width: '100%', 
        position: 'relative', 
        padding: '1rem',
    },
    chatArea: {
        width: '75%',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: '1rem',
        display: 'flex', 
        alignItems: 'center'
    },
    textarea: {
        display: 'block', 
        resize: 'none',
        borderRadius: '.5rem 0 0 .5rem',
        height: "60px",
        padding: '1rem',
        width: '90%', 
    },
    button: {
        textAlign: 'center',
        backgroundColor: 'teal',
        color: 'white',
        borderRadius: '0 .5rem .5rem 0',  
        height: '60px',
        width: '10%',
        display: 'block',
        cursor: 'pointer'
    },
    chat: {
        display: 'flex',
        paddingBottom: '.1rem',
    },
    chatContent: {
        padding: '1rem',
        backgroundColor: 'rgba(0, 0, 0, .08)',
        borderRadius: '.5rem',
        maxWidth: '55%'
    },
    chatTitle: {
        textAlign: 'center',
        backgroundColor: 'teal',
        color: 'white',
        padding: '1rem 0',
    }
}

export default ChatPage;