import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom'; 
import { Link } from 'react-router-dom';   
import NavigationBarComponent from './NavigationBarComponent';

const NavBarComponent = () => {
  const location =useLocation();
  const [activePage, setAcivePage] =useState('');
  const [showNavigation, setShowNavigation] =useState(false); 
  useEffect(() =>{
    setAcivePage(location.pathname.split('/')[1]); 
  }, [location]);   
  return (
    <header className='header'>
        <div className="title-bar"><strong>Terrazas de Guacuco</strong></div>
        <NavigationBarComponent onToggle={() =>setShowNavigation(!showNavigation)}/>
        <ul className={`links ${showNavigation? 'height-max-content': 'height-0'}`}>
            <li><Link to ='/' style={{...styles.link, color: activePage.toLowerCase() ===''? 'white': 'rgba(2555, 255, 255, .8)'}}>Home</Link></li>
            <li><Link to ='/about' style={{...styles.link, color: activePage.toLowerCase() ==='about'? 'white': 'rgba(2555, 255, 255, .8)'}}>About</Link></li>
            <li><Link to ='/services' style={{...styles.link, color: activePage.toLowerCase() ==='services'? 'white': 'rgba(2555, 255, 255, .8)'}}>Services</Link></li>
            <li><Link to ='/contact' style={{...styles.link, color: activePage.toLowerCase() ==='contact'? 'white': 'rgba(2555, 255, 255, .8)'}}>Contact</Link></li> 
            <li><Link to ='/login' style={{...styles.link, color: activePage.toLowerCase() ==='login' || activePage.toLowerCase() ==='forgot-password'? 'white': 'rgba(2555, 255, 255, .8)'}}>Login</Link></li> 
            <li><Link to ='/register' style={{...styles.link, color: activePage.toLowerCase() ==='register'? 'white': 'rgba(2555, 255, 255, .8)'}}>Register</Link></li>
        </ul>
    </header>
  )
}

const styles ={
  link: { 
    color: 'rgba(2555, 255, 255, .8)'
  }
}

export default NavBarComponent;