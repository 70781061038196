import React, {useState} from 'react';
import { PortalNavigationComponent, InputComponent, BannerCenterComponent, TableComponent } from '../components';
import {useForm} from 'react-hook-form';

const EventsPage = () => { 
    const {control, handleSubmit, reset} =useForm(); 
    const th =['TID', 'Amount', 'Date' ]
    const [amountPaid, setAmountPaid] =useState({amount: ''});
    const onSubmit =({amount}) =>{ 
        setAmountPaid(f => ({...f,amount})); 
        reset(amountPaid); 
        setAmountPaid(f => ({...f,amount: ''}));
        
    }  
  return (
    <div style={styles.page}>
        <PortalNavigationComponent /> 
        <main style={styles.grid}> 
        <BannerCenterComponent title="Payments"/>
        <div style={styles.payment_area}>
             <div>
                <h2 style={{paddingBottom: '1rem'}}>Previous Transactions</h2>
                <TableComponent headings={th} style ={styles.table}/>
             </div>
             <div>
                <form style={styles.form}>
                    <h2>Make Payment</h2>
                    <InputComponent control={control} name ='amount' placeholder='Enter amount to pay' rules={{required: 'Specify the amount to pay*', min:{
                        value: 1,
                        message: 'Atleast $1 accepted'
                        }, pattern: {
                        value: /^[0-9]+\.?[0-9]*$/,
                        message: 'Amount is a real number'
                    }}}/>
                    <button style={styles.button} onClick ={handleSubmit(onSubmit)}>Pay</button>
                </form>
             </div>
        </div> 
        </main>
    </div>
  )
}

const styles ={ 
    page: {
        display: 'flex', 
        flexDirection: 'column',
        minHeight: '100vh', 
        
    }, 
    button: {
        backgroundColor: 'teal',
        color: 'white',
        padding: '1rem 3rem',
        display: 'block',
        width: 'max-content', 
        cursor: 'pointer',
        marginTop: '1rem'
    },  
    payment_area: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        padding: '2rem',
        gap: '2rem',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0
    },
    table: {
        width: '100%',
        margin: 0,
        marginTop: '1rem'
    }
}

export default EventsPage;