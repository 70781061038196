import React, {useState, useEffect} from 'react';
import { NavbarComponent } from '../components';
import {InputComponent} from '../components';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector} from 'react-redux';
import MD5 from 'crypto-js/md5';
import {login} from '../API/features/auth';
import { Link, useNavigate } from 'react-router-dom'; 

const LoginPage =() =>{
  const navigate =useNavigate();
  const {control, handleSubmit, reset} =useForm();
  const [formFields, setFormFields] =useState({ email: '', password: '',});
  const dispatch =useDispatch(); 
  
  const onSignin =({email, password}) =>{
    setFormFields({...formFields, email, password});
    dispatch(login({email, password: MD5(password).toString()}))
    reset(formFields);
    setFormFields({...formFields, email: '', password: ''}); 
  }
  const {isLoggedIn} =useSelector(state => state.auth);   

  useEffect(() =>{ 
    if(isLoggedIn) { 
      navigate('/user-redirect');
    } 

  }, [isLoggedIn]); 
  return (
    <div className='auth-page'>
      <NavbarComponent />
      <div className="auth-section">
          <form action="" className='form'>
              <h2>Sign in</h2>   
              <InputComponent control={control} type="email" name='email'placeholder='Email Address' rules={{required: "Email required*"}}/> 
              <InputComponent control={control} type="password" name='password'placeholder='Password' rules={{required: "Password required*"}}/> 
              <div style={styles.mutedLink}><Link to='/forgot-password' style={styles.greyLink}>Forgot password?</Link></div> 
              <div className="form-group"><button type='submit' className='btn' onClick={handleSubmit(onSignin)}>Sign in</button></div>
              <div style={styles.centeredLink}>New here? <Link to='/register' style={styles.blueLink}>Signup</Link></div> 
          </form>
      </div>
    </div>
  )
}

const styles ={
  mutedLink: {
    width: '100%',
    textAlign: 'right', 
  },
  greyLink:{
    color: 'rgba(0,0,0,.6)',
  },
  blueLink: {
    color: 'dodgerblue', 
  },
  centeredLink: {
    width: '100%',  
    textAlign: 'center',
    paddingTop: '2rem',
    color: '#333',
  }
}
export default LoginPage;