import React, {useEffect, useState} from 'react';  
import { useNavigate } from 'react-router-dom'; 

import {ChatComponent, EventRegistrationComponent, ManageNavigationComponent, ManagementNavigation, TableComponent, UserAccessManagementComponent} from '../components'; 

const AdminPage =() =>{ 
  
  const [navigation, setNavigation] =useState(['Security', 'Managers', 'Users', 'Report',])
  const navigate =useNavigate() 
  const {user, isLoggedIn} = JSON.parse(localStorage.getItem('current-user'))
  const [page, setPage] =useState('');  

  useEffect(() =>{ 
    if(!isLoggedIn) navigate('/logout'); 
    setNavigation(f => user.role ==='garden-manager'? ['Timings', 'Residents', 'Visitors', 'Chats', 'Events'] :navigation)
    setPage(page.toLowerCase()) 
  }, [page, isLoggedIn, user]); 

  return (
    <>
    <ManagementNavigation />
    <div className='container'> 
    <ManageNavigationComponent features={navigation} setPage={setPage}/>
      <section className ="right-content">
        <div className="cards">
          <div className="card">
            <p>Assets</p>
            <div className="">20,000</div>
          </div>
          <div className="card">
            <p>Managers</p>
            <div className="">154,050</div>
          </div>
          <div className="card">
            <p>Residents</p>
            <div className="">1,560</div>
          </div>
          <div className="card">
            <p>Visitors</p>
            <div className="">9</div>
          </div>
        </div> 
        {
          page ==='events'?(
          <div style={styles.flex}> 
            <TableComponent headings ={[]} data={[]}/>
            <EventRegistrationComponent eventType={'Garden'}/>
          </div>

          ):  page ==='chats'? (
            <ChatComponent />
          )
          : page ==='visitors' || page ==='residents'? (
            <UserAccessManagementComponent />
          ): ''
        }
      </section>
    </div>
    </>
  )
}
 
const styles ={

  flex: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '2rem',
  },
}
export default AdminPage;