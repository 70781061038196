import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom"; 

const ProtectedRoutesComponent = ({climitedTo}) => { 
  const location =useLocation();
  const isLoggedIn =true;
  return isLoggedIn ?<Outlet /> :<Navigate to='/login' from ={{location}} replace /> 
  
}

export default ProtectedRoutesComponent;