import axios from "axios";
import { SERVER_URL } from "../../utils/constants";

const axiosInstance =(token =null) =>{ 
    const authorization =token? {'Authorization': `Bearer ${token}`} : {} 
    return axios.create({
        baseURL: SERVER_URL,  
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            ...authorization,
        }, 
    })  
}

export default axiosInstance;