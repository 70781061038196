import {createSlice} from '@reduxjs/toolkit';
import {LOADING, DONE} from '../../utils/constants';
import post from '../axios/post'; 
import MD5 from 'crypto-js/md5';

const users ={
    value: [],
    new_user: null,
    status: LOADING,
    error: null
}

export const userSlice =createSlice({
    name: 'users',
    initialState: users,
    reducers: { 
        postUser: async (state, action) =>{ 
            const message =''
            delete action.payload.cpassword; 
            const {password} =action.payload; 
            const {statusCode, data} =await post('users', {...action.payload, password: MD5(password).toString()});
            if([400, 409, 500].find(code => code === statusCode)) state.error =message;
            else state.new_user =data;
            state.status =DONE;
        },
        getUser: async ( state, action) =>{
            state.value =[{
                name: 'Meltus Bwire',
                role: 'resident',
                phone: '555-555-555',
                email: 'meltus@gmail.com',
            }]
        }
    }
})

export const {postUser, getUser} =userSlice.actions;
export default userSlice.reducer;