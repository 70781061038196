import React,   {useEffect}from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../API/features/auth';

const LogoutPage = () => {
  const navigate =useNavigate();
  const dispatch =useDispatch();
  const {token} =useSelector(state =>state.auth); 
  
  useEffect(() =>{

    if(token) dispatch(logout(token));
    navigate('/login');
    
  }, [token]) 
  return (
    <div>Logiing out</div>
  )
}

export default LogoutPage