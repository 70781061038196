import axiosInstance from './axiosInstance';
import { SERVER_URL } from '../../utils/constants';

const post =async(collection, data, withCredentials =false, token =null) =>{ 
    const axios =axiosInstance(SERVER_URL,withCredentials, token)
    const response =axios.post(`${collection}/new`, data); 
    return {data: await response.data, statusCode: response.status}

}

export default post;