import React from 'react';
import {BannerCenterComponent, NavbarComponent} from '../components'; 

const ContactPage = () => {
  return (
    <>
        <NavbarComponent />
        <BannerCenterComponent title='Contact us'/>
        <section className='contact-us'> 
            <form action="" className='form'> 
             <div className="form-row">
                    <div className="form-group"><input type="text" name='first-name'placeholder='First Name'/></div>
                    <div className="form-group"><input type="text" name='last-name'placeholder='Last Name'/></div>
                </div>
                <div className="form-row">
                    <div className="form-group"><input type="text" name='phone'placeholder='Phone Number'/></div>
                    <div className="form-group"><input type="email" name='email'placeholder='Email Address'/></div>
                </div>
                <div className="form-group">
                    <textarea name="message" rows="10" placeholder='Query'></textarea>
                </div>
                <div className="form-group">

                <button type='submit' className='btn'>Submit</button>
                </div>
            </form>
            
        </section>
    </>
  )
}

export default ContactPage;