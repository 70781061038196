import React from 'react';
import House from '../img/bg-img.jpg';

const BannerCenterComponent = ({title}) => {
  return (
    <section className="centered-banner">
      <img src={House} alt ="house" className='hero-image'/>
      <div className="overlay" style={styles.overlay}>
        <div className="hero-content"><h2>{title}</h2></div> 
      </div>
    </section>
  )
} 
const styles ={
  overlay: {
    justifyContent: 'center',
    alignItems: 'center'
  }
}
export default BannerCenterComponent;