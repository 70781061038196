import React, {useState, useEffect} from 'react'
import { useLocation, Link } from 'react-router-dom'; 
import NavigationBarComponent from './NavigationBarComponent';

const PortalNavigationComponent = () => {
  const location =useLocation();
  const [activePage, setAcivePage] =useState('') 
  const [username,setUsername] =useState('')
  const [role,setRole] =useState('')
  const [showNavigation, setShowNavigation] =useState(false);
  useEffect(() =>{
    const split_path =location.pathname.split('/');
    const uri =split_path.length >2? split_path[2]: split_path[1]
    setAcivePage(uri); 
    setUsername(JSON.parse(localStorage.getItem('current-user')).user.name.split(' ')[0]);
    setRole(JSON.parse(localStorage.getItem('current-user')).user.role);
    return () =>{
      setUsername('')
    }
  }, [location]); 
  return (
    <header className='header' style={styles.header}> 
      <h3 style={{color: 'white'}}>Welcome, {username}</h3> 
      <NavigationBarComponent onToggle={() =>setShowNavigation(!showNavigation)}/>
      <ul className={`links ${showNavigation? 'height-max-content': 'height-0'}`}>
          <li style={styles.li}><Link to='/portal' style={{...styles.link, color: activePage.toLowerCase() ==='portal'? 'white': 'rgba(2555, 255, 255, .8)'}}>Home</Link></li>
          <li style={styles.li}><Link to='/portal/chats' style={{...styles.link, color: activePage.toLowerCase() ==='chats'? 'white': 'rgba(2555, 255, 255, .8)'}}>Chats</Link></li>
          <li style={styles.li}><Link to='/portal/events' style={{...styles.link, color: activePage.toLowerCase() ==='events'? 'white': 'rgba(2555, 255, 255, .8)'}}>Events</Link></li>
          <li style={styles.li}><Link to='/portal/membership' style={{...styles.link, color: activePage.toLowerCase() ==='membership'? 'white': 'rgba(2555, 255, 255, .8)'}}>Membership</Link></li>
          {role ==='resident'?<li style={styles.li}><Link to='/portal/payments' style={{...styles.link, color: activePage.toLowerCase() ==='payments'? 'white': 'rgba(2555, 255, 255, .8)'}}>Payments</Link></li>: <li style={styles.li}><Link to='/portal/map' style={{...styles.link, color: activePage.toLowerCase() ==='map'? 'white': 'rgba(2555, 255, 255, .8)'}}>Navigation Map</Link></li>}
          <li style={styles.li}><Link to='/logout' style={styles.link}>Logout</Link></li>
      </ul>
    </header>
  )
}

const styles ={
  header: {
    backgroundColor: '#8B7E74'
  },
  ul: { 
    display: 'flex', 
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '1rem',

  },
  li: {
    padding: '.5rem 0'
  }, 
  link: {
    color: 'rgba(2555, 255, 255, .8)'
  }
}
export default PortalNavigationComponent;