import React from 'react'; 

import { NavbarComponent, BannerCenterComponent } from '../components';
import Residential from '../img/residential-home.jpg';
import Commercial from '../img/commercial.jpg';
import Farm from '../img/farm.jpg';

const ServicesPage = () => {
  return (
    <>
        <NavbarComponent />
        <BannerCenterComponent title='Services'/>
        <div className="our-services"> 
            <section className="services-section">
                <div className="service">
                    <div className="photo"></div>
                    <h2>Manage Buildings</h2> 
                    <p className="service-statement">We know, east or west, hom is best. We make a good home out of any residential unit. Our planning team will turn any residence into a home you've been dreaming of. The agency crafts a serene environment, subdivide the unit into sub-units which families could use for provate activities</p>
                </div>
                <div className="service">
                <div className="photo"></div>
                    <h2>Management of Gardens and Surroundings</h2> 
                    <p className="service-statement">Studies show that the backbone of most economies is agriculture. Digitizing farm machineries help our clients maximize on their output. We are trusted to manage even state farm assets. Our optimal solution is not only profit efficient, but also environment friendly. We are environemtalists.</p>
                </div>
                <div className="service">
                <div className="photo"></div>
                    <h2>Management of Visitors</h2> 
                    <p className="service-statement">Pro man effectively manages business buildings for you. We maximize input to help busnesses running in the premise we manage to maximize on their profits. Over 30% of our business model is about profit maxizing coaching. A training we offer freely to business classes using our premises.</p>
                </div>
            </section>
        </div>
    </> 
  )
}

export default ServicesPage;