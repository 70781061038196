import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { postUser } from '../API/features/users';
import { NavbarComponent } from '../components';
import {InputComponent} from '../components';
import { useForm } from 'react-hook-form'; 
import { PHONEREGEX, EMAILREGEX, NAMEREGEX } from '../utils/constants'; 
import { Link, useNavigate } from 'react-router-dom';

const RegisterPage = () => {
  const {control, handleSubmit, reset, register, watch, formState: {errors}} =useForm(); 
  const [formFields, setFormFields] =useState({name: '', email: '', phone: '', role: '', password: '', cpassword: ''});
  const navigate =useNavigate()
  const password =watch('password');
  const dispatch =useDispatch();
  const onSignup =data =>{
    setFormFields({...formFields, ...data}); 
    dispatch(postUser(data));
    reset(formFields);
    navigate('/login')
    
  }
  const {error, new_user} =useSelector(({users}) =>users);
  useEffect(() =>{   
    return () =>{ 
      setFormFields({}); 
    }
  }, [formFields])

  return (
    <div className='auth-page'> 
      <NavbarComponent />
      <section className='auth-section'>  
          <form action="" className='form'>
              <h2>Register</h2> 
              {error &&(<div >{'error'}</div>)}
              {new_user &&(<div >{'success'}</div>)}
              <InputComponent control={control} type="text" name='name' placeholder='Full name' 
              rules={{required: "Name required*", pattern: {
                  value: NAMEREGEX,
                  message: "A name can only have spaces and alphabets"
              }}}/>
              <InputComponent control={control} type="email" name='email' placeholder='Email address' 
              rules={
                {
                  required: "Email required*",
                  pattern: {
                    value:EMAILREGEX,
                    message: 'Invalid email'
                  }
                }
              }/>
              <InputComponent control={control} type="tel" name='phone' placeholder='Phone number' rules={{
                required: "Phone required*",
                pattern: {
                  value: PHONEREGEX,
                  message: 'Invalid phone number'
                }
              }}/> 
              <div className="form-group">
                <select  name = "role" defaultValue="" {...register('role', {required: "Role required*"})} style ={{borderColor: errors.role? 'brown': null}}>
                  <option value="" disabled>User role</option>
                  <option value="visitor">Visitor</option>
                  <option value="resident">Resident</option> 
                  <option value="pool-manager">Pool Manager</option>
                  <option value="garden-manager">Garden Manager</option>
                  <option value="security-manager">Security Manager</option>
                  <option value="building-manager">Building Manager</option>
                </select>
                {errors.role &&(<small style={styles.small}>{errors.role.message || 'Invalid input'}</small>)}
              </div>
              <InputComponent control={control} type="password" name='password' placeholder='Password' 
              rules={{
                required: "Password required*" ,
                minLength: {value: 8, message: 'Password should be atleast 8 characters long'}
              }}
              />
              <InputComponent control={control} type="password" name='cpassword' placeholder='Confirm Password' 
              rules={{
                required: "Verify Password*",
                validate: value => value === password || 'Passwords do not match'
              }}
              />
              <div className="form-group"><button type='submit' className='btn' onClick={handleSubmit(onSignup)}>Register</button> </div>
              <div style={styles.centeredLink}>Have an account? <Link to='/login' style={styles.blueLink}>Sign in</Link></div>
          </form>
      </section>
    </div>
  )
}
const styles ={
  small: {
    color: 'brown',
    fontSize: '12px',
    display: 'block',
    position: 'absolute',
    bottom: '-4px', 
    left: '0px', 
  },
  blueLink: {
    color: 'dodgerblue', 
  },
  centeredLink: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '2rem',
    color: '#333',
  }
}
export default RegisterPage;