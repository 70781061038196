import React, {useState} from 'react';   
import { ManageNavigationComponent, ManagementNavigation } from '../components';

const ManageBuildingsPage =() =>{ 
  const [page, setPage] =useState('dashboard')
  const [showNavigation, setShowNavigation] =useState(false); 
  return (
    <> 
    <ManagementNavigation toggleNavigation={() =>setShowNavigation(!showNavigation)}/>
      <div className="grid-container">
      <ManageNavigationComponent features={['Managers', 'Users',  'Chat','Reports',]} setPage={setPage} showNavigation={showNavigation}/>
        <div className="grid-ui" >
        <div className="table-container"> 
        <h2 className="h2">Mangers</h2>
        <table className="table"> 
        <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Community</th>
              <th>Size</th>
              <th>Cost</th>              
            </tr> 
        </thead>
            <tr>
              <th>1</th>
              <td>Lotus Garden</td>
              <td>Brigade</td>
              <td>2009</td>
              <td>$100</td>
            </tr>
            <tr>
              <th>2</th>
              <td>Flower Dome</td>
              <td>RMZ</td>
              <td>2012</td>
              <td>$800</td>
            </tr>
            <tr>
              <th>3</th>
              <td>The Patch</td>
              <td>Sobha</td>
              <td>2010</td>
              <td>$270</td>
            </tr>
             <tr>
              <th>4</th>
              <td>ArborOak</td>
              <td>Sobha</td>
              <td>2009</td>
              <td>$500</td>
            </tr>
            <tr>
              <th>5</th>
              <td>Ivy</td>
              <td>Brigade</td>
              <td>2012</td>
              <td>$160</td>
            </tr>
          
        </table>
        </div>
        <div className="table-container"> 
            <h2 className="h2">Security</h2>
            <table className="table"> 
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Community</th>
                  <th>Floor</th>
                  <th>Occupancy</th>              
                </tr>
              
              
                <tr>
                  <th>1</th>
                  <td>Hill View</td>
                  <td>Brigade</td>
                  <td>40</td>
                  <td>50%</td>
                </tr>
                <tr>
                  <th>2</th>
                  <td>Water View</td>
                  <td>RMZ</td>
                  <td>50</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <th>3</th>
                  <td>Forest View</td>
                  <td>Sobha</td>
                  <td>200</td>
                  <td>20%</td>
                </tr>
                 <tr>
                  <th>4</th>
                  <td>Lake View</td>
                  <td>Sobha</td>
                  <td>35</td>
                  <td>10%</td>
                </tr>
                <tr>
                  <th>5</th>
                  <td>Metropolis</td>
                  <td>Brigade</td>
                  <td>45</td>
                  <td>30%</td>
                </tr>
              
            </table>
            </div>
            <div className="">
            <h2 className="h2">Add User</h2>
            <form action="" className="grid-form">
              <div className="inputs">
                <input type='text' placeholder='Some input'/>
                <input type='text'placeholder='Some input'/>
                <select>
                  <option value="">User role</option>
                </select>
              </div>
              <div className="text-area">
                <textarea rows="5" placeholder='Type here your'></textarea>
                <input type='submit' value='Some Button'/> 
              </div>
            </form>
            </div>
            <div className="table-container"> 
                <h2 className="h2">Residents</h2>
                <table className="table"> 
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Community</th>
                      <th>Apartment</th>
                      <th>Services</th>              
                    </tr>
                  
                  
                    <tr>
                      <th>1</th>
                      <td>Aneesh</td>
                      <td>Brigade</td>
                      <td>112</td>
                      <td>W.E</td>
                    </tr>
                    <tr>
                      <th>2</th>
                      <td>Elizabeth</td>
                      <td>RMZ</td>
                      <td>460</td>
                      <td>L.W.E</td>
                    </tr>
                    <tr>
                      <th>3</th>
                      <td>Vyashnavi</td>
                      <td>Sobha</td>
                      <td>265</td>
                      <td>E</td>
                    </tr>
                     <tr>
                      <th>4</th>
                      <td>Chotta</td>
                      <td>Sobha</td>
                      <td>14</td>
                      <td>E.L</td>
                    </tr>
                    <tr>
                      <th>5</th>
                      <td>Stelly</td>
                      <td>Brigade</td>
                      <td>987</td>
                      <td>G.W.E.L</td>
                    </tr>
                  
                </table>
                </div>
        </div>
        
      </div>
    </>
  )
}

export default ManageBuildingsPage;