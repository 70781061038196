import './App.css';  
import {Route, Routes, BrowserRouter as Router} from 'react-router-dom';
import { HomePage, AboutPage, AdminPage, ContactPage, ServicesPage, LoginPage, RegisterPage, ManageBuildingsPage, ManageVisitorsPage, ManageResidentsPage, ChatPage, PaymentsPage, MembershipPage, EventsPage, PortalPage, MapPage, LogoutPage, ForgotPasswordPage, ResidentsPage, PortalRedirectPage, ManageSecurityPage, ManagePoolsPage, ManageGardensPage} from './pages';
import { ProtectedRoutesComponent } from './components';

const App =() =>{
  return ( 
    <Router> 
      <Routes>

        <Route exact path='/' element={<HomePage />} /> 
        <Route path='/about' element={<AboutPage />} /> 
        <Route path='/services' element={<ServicesPage />} /> 
        <Route path='/contact' element={<ContactPage />} /> 
        <Route path='/register' element={<RegisterPage />} /> 
        <Route path='/login' element={<LoginPage />} />  
        <Route path='/forgot-password' element={<ForgotPasswordPage />} /> 
        <Route element ={<ProtectedRoutesComponent />}>
          <Route path='/logout' element ={<LogoutPage/>}/> 
          <Route path='/user-redirect' element ={<PortalRedirectPage />}/>  
          <Route path='/portal' element ={<PortalPage />}/>  
          <Route path='/portal/residents' element={<ManageResidentsPage />} />   
          <Route path='/portal/visitors' element={<ManageVisitorsPage />} /> 
          <Route path='/portal/payments' element={<PaymentsPage />} />   
          <Route path='/portal/map' element ={<MapPage />}/> 
          <Route path='/portal/chats' element={<ChatPage />} />   
          <Route path='/portal/membership' element={<MembershipPage />} />   
          <Route path='/portal/events' element={<EventsPage />} />   
          <Route exact path='/admin' element={<AdminPage />} /> 
          <Route path='/admin/buildings-manager' element={<ManageBuildingsPage />} /> 
          <Route path='/admin/pools-manager' element={<ManagePoolsPage />} /> 
          <Route path='/admin/gardens-manager' element={<ManageGardensPage />} /> 
          <Route path='/admin/security-manager' element={<ManageSecurityPage />} /> 
        </Route>
          
      </Routes> 
    </Router> 
  );
} 

export default App;
