import React from 'react'

const NavigationBarComponent = ({onToggle}) => {
  return (
    <button className='bars' onClick={() =>onToggle()}>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </button>
  )
}

export default NavigationBarComponent;