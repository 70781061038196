import React, {useEffect} from 'react'; 
import { useSelector } from 'react-redux';   
import { useNavigate } from 'react-router-dom'; 

 
const PortalRedirectPage =() =>{  
  const navigate =useNavigate() 
  const {user, isLoggedIn} =useSelector(state =>state.auth);  

  useEffect(() =>{ 
    if(!isLoggedIn) navigate('/logout');
    else{
      if(user.role ==='resident' || user.role ==='visitor') navigate('/portal');
      else {
          if(user.role ==='pool-manager') navigate('/admin/pools-manager');
          if(user.role ==='building-manager') navigate('/admin/buildings-manager');
          else if(user.role ==='garden-manager') navigate('/admin/gardens-manager');
          else if(user.role ==='security-manager') navigate('/admin/security-manager'); 
      }
    }
 
  }, [isLoggedIn]); 

  return (
    <></>
  )
}
  
export default PortalRedirectPage;