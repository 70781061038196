import React, {useEffect} from 'react'; 
import {useDispatch, useSelector} from 'react-redux';
import { NavbarComponent } from '../components'; 
import { postCar } from '../API/features/cars';
import House from '../img/bg-img.jpg';
import Building from '../img/building.jpg';

const HomePage =() =>{
  const dispatch =useDispatch()
  useEffect(() =>{
    dispatch(postCar)
  }, [dispatch]);
  
  const  carSelector =useSelector(state => state.cars)  
  return (  
    <main>
      <NavbarComponent />
      <section className='hero-section'>
         <img src={House} alt ="house" className='hero-image'/>
         <div className="overlay">
          <div className="hero-content">
            <h2>Terrazas de Guacuco</h2>
            <p>Residential managers offers the most convenient way of managing properties. Through our digital strategy owners can monitor their properties as we do the hard work of maintainance. We are endosed by millions of properties, you will love us.</p>
            <a href="/" className='a-cta'>Get Started</a>
          </div> 
         </div>
      </section>
      <section className='showcase-section'>
        <h2>Which one do you own?</h2>
        <div className="cols">
          <img src={Building} alt ='building'/>
          <div className="building-cards">
              <div className="building-card"> 
                <div className="content">
                  <h3 className='mini-title'>Residential</h3>
                  <p>We are more focused with residential properties, to effectively secure and maintain them.</p>
                </div>
              </div>
              <div className="building-card"> 
                <div className="content">
                  <h3 className='mini-title'>Assets</h3>
                  <p>Add new net to your wealth from your idle assets. We advice on what can be done to maximize profits</p>
                </div>
              </div>
              <div className="building-card"> 
                <div className="content">
                  <h3 className='mini-title'>Rentals</h3>
                  <p>Tenants can be naughty. Make most out of your rental houses by hiring pro man as your new manager.</p>
                </div>
              </div>
              <div className="building-card"> 
                <div className="content">
                  <h3 className='mini-title'>Commercial</h3>
                  <p>With our AI based business model, We help our clients make seasonal profitable decisions.</p>
                </div>
              </div>
          </div>
        </div>
      </section>
    </main>
  )
}


export default HomePage;