import React from 'react';
import TableComponent from './TableComponent';


const UserAccessManagementComponent = () => {
  return (
    <div>
        <h2>Manage Resident access</h2>
        <TableComponent headings={[]} data={[]}/>
    </div>
  )
}

export default UserAccessManagementComponent;