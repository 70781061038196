import React, {useState} from 'react';
import { useDispatch} from 'react-redux';
import { useForm } from 'react-hook-form';
import InputComponent from './InputComponent';
import { postEvent} from '../API/features/events'; 

const EventRegistrationComponent = ({eventType}) => {

    const dispatch =useDispatch();
    const {control, handleSubmit, reset} =useForm();
    const [formData, setFormData] =useState({});  

    const onSubmit =data =>{ 
        setFormData(data);
        dispatch(postEvent(data));  
        reset(formData);
        setFormData({})
    }    
    return (

        <form style={styles.form}> 
            <h1>New {eventType} Event</h1>
            <InputComponent control={control} name ="event_name" placeholder='Event name' rules={{required: 'Event name required*'}}/>
            <InputComponent control={control} name ="event_date" type='date'  rules={{required: 'Set an event date*'}}/> 
            <InputComponent control={control} name ="event_venue" type='text' placeholder='Venue of the event'  rules={{required: 'Venue is required'}}/> 
            <InputComponent control={control} name ="event_description" type='text' placeholder='Briefly describr your event' multiline  rules={{required: 'Describe your event*'}}/> 
            <button onClick={handleSubmit(onSubmit)} style ={styles.button}>Add Event</button>
        </form>
    )
}

const styles ={
    button: {
        marginTop: '1rem',
        padding: '1rem 2rem',
        backgroundColor: 'teal',
        color: 'white',
        borderRadius: '.5rem',
        cursor: 'pointer'
    },
    form: { 
        width: '100%',
        padding: 0,
        margin: 0,
      },
}
export default EventRegistrationComponent;