import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { PortalNavigationComponent, TableComponent, ModalComponent, InputComponent } from '../components';


const PortalPage = () => {
  const [openModal, setOpenModal] =useState(false);
  const [updateDetails, setUpdateDetails] =useState(false); 
  const {control, handleSubmit} =useForm();
  const onSubmit =data =>{
    console.log(data);
  }
  const role ='visitor';
  const header =role ==='visitor'? ['Name', 'Email', 'Phone', 'DL']: []
  const onOpen =() => setOpenModal(!openModal) 
  const today = new Date();
  const lastDateOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0).getDate(); 
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDay()+1;  
  const days =[]; let week =[]; 
  for(let i =1; i<= firstDayOfMonth; i++) week.push('');
  for(let i =1; i<= lastDateOfMonth; i++){
    week.push(i);
    if(week.length%7 ===0){
      days.push(week);
      week =[];
    }
    
  }
  if(week.length >0) days.push(week)  
  week =[]; 
  return (
    <>
    <PortalNavigationComponent />
    <div>
        <div style={styles.cards}>
            <div style={styles.card}>
                <h3>10</h3> 
                <p>Unread Messages</p>
            </div>
            <div style={styles.card}>
                <h3>10</h3> 
                <p>Upcoming events</p>
            </div>
            <div style={styles.card}>
                <h3>10</h3> 
                <p>Membership groups</p>
            </div>
        </div>
        <div style={styles.mainContent}>
          <div style={styles.content}>
            <h2 style={styles.title}>Activity Timings</h2>
            <TableComponent headings={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']} data={days}/>
          </div>
          <div style={styles.content}>
            <h2 style={styles.title}>{role ==='visitor'? 'Personal Information': 'Vehicle'}</h2> 

            <TableComponent headings={header} data={[]} style={{width: '100%'}}/>

            {role ==='resident' ?<button onClick={() =>onOpen()} style ={styles.floatingButton}>Add Vehicle</button>: <button onClick={() =>setUpdateDetails(!updateDetails)} style ={styles.floatingButton}>Update details</button>} 
            
            {openModal && ( 
            <ModalComponent>
                <form >
                    <h1>Vehicle details</h1>
                    <InputComponent control={control} name ="plate" placeholder='Vehicle Plate' rules={{required: 'Car plate is required*'}}/>
                    <InputComponent control={control} name ="brand" type='text' placeholder='Vehicle Brand' rules={{required: 'Car brand is required*'}}/> 
                    <InputComponent control={control} name ="model" type='text' placeholder='Vehicle Model'  rules={{required: 'Car model is required'}}/>  
                    <button onClick={handleSubmit(onSubmit)} style ={styles.button}>Save details</button>
                </form>
            </ModalComponent>
            ) }
            {updateDetails && ( 
            <ModalComponent>
                <form >
                    <h1>Update details</h1>
                    <InputComponent control={control} name ="name" placeholder='Full name' rules={{required: 'Name required*'}}/>
                    <InputComponent control={control} name ="email" placeholder='Email address' type='email' rules={{required: 'Email required*'}}/>
                    <InputComponent control={control} name ="phone"  placeholder='Phone number' rules={{required: 'Phone required*'}}/> 
                    <InputComponent control={control} name ="dl" type='text' placeholder='Driving Licence'  rules={{required: 'DL required* '}}/>  
                    <button onClick={handleSubmit(onSubmit)} style ={styles.button}>Save details</button>
                </form>
            </ModalComponent>
            ) }
          </div>
        </div>
    </div>
    </> 
  )
}
const styles ={
  cards: {
    display: 'flex',
    width: '80%',
    margin: '0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: '2rem',
    padding: '2rem',
  },

  card: {
    boxShadow: '0px 0px 3px rgba(0,0,0,.15)',
    padding: '2rem',
    borderRadius: '.5rem',
    flex: 1
  },

  mainContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '0 2rem',
  },

  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1rem',
    textAlign: 'center',
  },

  title: {
    color: 'rgba(0,0,0,.7)',
    fontSize: '1.25rem',
    fontWeight: 700,
    textTransform: 'uppercase', 
  },

  floatingButton: {
    backgroundColor: 'teal',
    color: 'white', 
    display: 'flex', 
    cursor: 'pointer',
    borderRadius: '.1rem',
    fontSize: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: '1rem',
    bottom: '1rem',
    zIndex: 9,
    padding: '1rem 2rem',
},

button: {
  backgroundColor: 'teal',
  color: 'white',
  padding: '1rem 2rem',
  display: 'block',
  width: 'max-content',
  alignSelf: 'center',
  cursor: 'pointer',
  marginTop: '1rem',
},

}

export default PortalPage;