import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';   
import NavigationBarComponent from './NavigationBarComponent';

const ManagementNavigation = ({toggleNavigation}) => { 
  const [username,setUsername] =useState('')
  useEffect(() =>{
    setUsername(JSON.parse(localStorage.getItem('current-user')).user.name.split(' ')[0]);
    return () =>{
      setUsername('')
    }
  }, []);
  
  return (
    <header style={styles.header}> 
      <h3 style={{color: 'white'}}>Welcome, {username}</h3> 
      <ul style ={styles.ul}>  
          <li style={styles.li} className='sm-hide'><Link to='/logout' style={styles.link}>Logout</Link></li>
          <NavigationBarComponent onToggle={toggleNavigation}/> 
      </ul>
    </header>
  )
}

const styles ={
  header: {
    backgroundColor: '#8B7E74',
    display: 'flex',
    padding: '.75rem 1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ul: { 
    display: 'flex', 
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '1rem',

  },
  li: {
    padding: '.5rem 0'
  }, 
  link: {
    color: 'rgba(2555, 255, 255, .8)'
  }
}
export default ManagementNavigation;