import React, {useState} from 'react'; 
import { NavbarComponent, BannerCenterComponent, PortalNavigationComponent, InputComponent, ManagementNavigation } from '../components';
import { useForm } from 'react-hook-form';
const ManageResidentsPage =() =>{
  const dates =['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']; 
  for(let i =1; i<=31; i++) dates.push(i);
  const {control, } =useForm()
  const [showNavigation, setShowNavigation] =useState(false); 
  return (
    <> 
    <ManagementNavigation toggleNavigation={() =>setShowNavigation(!showNavigation)}/>
      <BannerCenterComponent title="Residentials"/>
      <div className="grid-container">
        <div className="grid-ui">
        <div class="table-container"> 
          <h2 class="h2">Vehicle details</h2>
        
        </div> 
        <div className="">
          <div className="events">
            <h2>Events</h2>
          </div>
        </div>
          <div className="">
              <h2 class="h2">Add Vehicle</h2>
              <form action="" className="grid-form"> 
                <InputComponent control={control} name ="make" placeholder='Car make'/> 
                <InputComponent control={control} name ="model" placeholder='Car model'/> 
                <InputComponent control={control} name ="year" placeholder='Year made'/> 
                <InputComponent control={control} name ="cost" placeholder='Car price'/> 
                <div className="text-area"> 
                  <input type='submit' value='Save details'/> 
                </div>
              </form>
            </div> 
        </div> 
      </div>
    </>
  )
}


export default ManageResidentsPage;