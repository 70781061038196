import React from 'react'

const TableComponent = ({headings, data, style}) => {
  return (
    <div> 
      {
        data?.length>0? (
          <table style={{...style}}>
            <thead>
              <tr>{headings.map((heading,index) => (<th key ={index}>{heading}</th>))}</tr> 
            </thead>
            <tbody>
              {
                data.map((row, index) =>(
                    <tr key={index}>{row.map((col, id) =>(<td key={id}>{col}</td>))}</tr>
                ))
              }
            </tbody>
            </table> 
        ): <div style={styles.div}>No records found</div> 
      }
 
    </div>
  )
}

const styles ={
  div: {
    textAlign: 'center',
    padding: '1rem',
    border: '1px solid rgba(0,0,0,.2)',
    color: 'rgba(0,0,0,.5)',
    borderRadius: '.15rem',
  }
}
export default TableComponent