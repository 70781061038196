export const SERVER_URL ='https://server-backend-blsg.onrender.com/';

export const GET_ACTION ='get';
export const GET_ONE_ACTION ='get_one';
export const POST_ACTION ='post';
export const PUT_ACTION ='put';
export const DELETE_ACTION ='delete';

export const IDLE ='idle';
export const LOADING ='loading';
export const DONE ='done';
export const FAILED ='failed';

export const PHONEREGEX =/^(\(?\+?\d{1,3}\)?[- ]?)*(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/gi;
export const NAMEREGEX =/^[a-zA-Z ]+$/gi;
export const EMAILREGEX =/^[a-zA-Z0-9]+[.-]?[a-zA-Z0-9]+?@\w{2,}[.]?[a-zA-Z0-9]{2,}?[.]\w{2,}$/gi
