import React from 'react'
import {Controller} from 'react-hook-form';


const InputComponent = ({placeholder, type ='text', name, control, rules ={}, multiline =false}) => {
  return (
    <Controller 
        control={control} 
        name ={name} 
        rules ={rules}
        render ={({field: {value, onChange, }, fieldState: {error}}) =>( 
          <div className='form-group'>
            {multiline? <textarea rows={5} placeholder ={placeholder} value ={value} onChange ={onChange} style ={{borderColor: error? 'brown': null}}></textarea>:  <input type ={type} placeholder ={placeholder} value ={value} onChange ={onChange} style ={{borderColor: error? 'brown': null}}/>}
            {error &&(<small style={styles.small}>{error.message || 'Invalid input'}</small>)}
          </div>
                
        )}
    /> 
  )
}

const styles ={
  small: {
    color: 'brown',
    fontSize: '12px',
    display: 'block',
    position: 'absolute',
    bottom: '-4px', 
    left: '0px', 
  }
}
export default InputComponent;