import { configureStore} from "@reduxjs/toolkit"; 
import carReducer from './features/cars';
import authReducer from './features/auth';
import userReducer from "./features/users";
import eventReducer from "./features/events"; 

export const store =configureStore({
    reducer: {
        auth: authReducer,
        cars: carReducer,
        users: userReducer,
        events: eventReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false})
})