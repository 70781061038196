import React, { useState } from 'react'; 

const ModalComponent = ({children}) => {
    const [showModal, setShowModal] =useState(true);
    const onClose =() => setShowModal(!showModal)
    return (
        <>
            { showModal &&(
                <section style={styles.modal}>
                    <button style={styles.close} onClick ={() =>onClose()}>&times;</button> 
                    <div>{children}</div>
                </section>
            )
            
            }
        </>
    )
}

const styles = {
    modal: {
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0,0,0,.7)',
        zIndex: 99,
        padding: '2rem',
    },
    close: { 
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        fontSize: '3rem',
        color: 'white',
        backgroundColor: 'transparent',
        cursor: 'pointer'
    }, 
}
export default ModalComponent;