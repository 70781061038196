import React from 'react'
import { PortalNavigationComponent } from '../components';

const MapPage = () => {
  return (
    <>
    <PortalNavigationComponent />
    <iframe title='Navigation map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30688345.76045352!2d64.41841358311652!3d20.128374817698493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2ske!4v1680508584461!5m2!1sen!2ske" style={{border:0, display: 'block', width: '100vw', height: '90vh'}}allow="fullscreen" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </>
  )
}

export default MapPage;