import axiosInstance from "./axiosInstance";

export const get_access_token =async data =>{
    try {
        const axios =axiosInstance();
        const response =await axios.post(`auth/generate_token`, data);
        return {...await response.data, status: response.status}
        
    } catch ({response: {data: {reason}, status}}) {
        return {error: reason, status};
    }
} 

export const destroy_access_token =async token =>{
    try {
        const axios =axiosInstance(token);
        const response =await axios.delete(`auth/logout`);
        return {...await response.data, status: response.status}
        
    } catch ({response: {data: {reason}, status}}) {
        return {error: reason, status};
    }
} 
 