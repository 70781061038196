import React, {useState} from 'react';
import { NavbarComponent } from '../components';
import {InputComponent} from '../components';
import { useForm } from 'react-hook-form';
import { useDispatch} from 'react-redux'; 
import {login} from '../API/features/auth'; 

const LoginPage =() =>{
  const {control, handleSubmit, reset} =useForm();
  const [formFields, setFormFields] =useState({ email: '', password: '',});
  const dispatch =useDispatch()
  const onSignin =({email, password}) =>{
    setFormFields({...formFields, email, password});
    dispatch(login({email}))
    reset(formFields);
    setFormFields({...formFields, email: '', password: ''}); 

  }
  return (
    <div className='auth-page'>
      <NavbarComponent />
      <div className="auth-section">
          <form action="" className='form'>
              <h2>Reset Password</h2>   
              <InputComponent control={control} type="email" name='email'placeholder='Email address' rules={{required: "Email required*"}}/> 
              <div className="form-group"><button type='submit' className='btn' onClick={handleSubmit(onSignin)}>Reset Password</button></div> 
          </form>
      </div>
    </div>
  )
}

 
export default LoginPage;