import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {IDLE} from '../../utils/constants'

const cars ={
    value: [],
    status: IDLE,
    error: null
}

export const carSlice =createSlice({
    name: 'cars',
    initialState: cars,
    reducers: { 
        postCar: (state, action) =>{
            console.log(state.value);
        },

    }
})

export const {postCar} =carSlice.actions;
export default carSlice.reducer;