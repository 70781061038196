import React, { useState } from 'react';
import { PortalNavigationComponent, InputComponent, ModalComponent, BannerCenterComponent } from '../components';
import {useForm} from 'react-hook-form';

const EventsPage = () => { 
    const {control, handleSubmit} =useForm();
    const [openModal, setOpenModal] =useState(false);
    const joined =true;
    const onSubmit =data =>{
        console.log(data);
    }
    const onOpen =() => setOpenModal(!openModal) 
  return (
    <div style={styles.page}>
        <PortalNavigationComponent /> 
        <main style={styles.grid}> 
        <BannerCenterComponent title="Memberships"/>
        <div style={styles.events_list}>
            <div style={styles.eventContainer}>
                <h2>Group Name</h2>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit, corporis!</p>
                <div><button style={styles.eventCTA}>{joined? 'Exit Group': 'Join Group'}</button></div>
            </div> 
        </div>
        <button onClick={() =>onOpen()} style ={styles.circularButton}>+</button>
        {
            openModal && ( 
            <ModalComponent>
                <form >
                    <h1>Create Group</h1>
                    <InputComponent control={control} name ="group_name" type='text' placeholder='Group name'  rules={{required: 'Group name required*'}}/> 
                    <InputComponent control={control} name ="about_group" type='text' placeholder='Group bio' multiline  rules={{required: 'Group bio required*'}}/> 
                    <button onClick={handleSubmit(onSubmit)} style ={styles.button}>Create</button>
                </form>
            </ModalComponent>
            )
        }
        </main>
    </div>
  )
}

const styles ={ 
    page: {
        display: 'flex', 
        flexDirection: 'column',
        minHeight: '100vh', 
        
    }, 
    button: {
        backgroundColor: 'teal',
        color: 'white',
        padding: '1rem 2rem',
        display: 'block',
        width: 'max-content',
        alignSelf: 'center',
        cursor: 'pointer',
        marginTop: '1rem'
    },
    circularButton: {
        backgroundColor: 'teal',
        color: 'white', 
        display: 'flex',
        height: '50px',
        width: '50px',
        cursor: 'pointer',
        borderRadius: '50%',
        fontSize: '2rem',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        right: '1rem',
        bottom: '1rem',
        zIndex: 9,
    },
    eventContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1rem',
        border: '1.5px solid rgba(0,0,0,.2)',
        borderRadius: '.5rem',
    },
    events_list: {
        padding: '1rem 2rem',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '2rem',
    },
    eventCTA: {
        backgroundColor: 'rgba(0,0,0,.6)', 
        padding: '1rem 2rem',
        color: 'aliceblue', 
        cursor: 'pointer'
    }
}

export default EventsPage;