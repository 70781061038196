import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'; 
import { destroy_access_token, get_access_token } from '../axios/auth';
import axios from 'axios';

const initialState ={
    user: null,
    token: null,
    isLoggedIn: false, 
}

export const login =createAsyncThunk("login", async data => await get_access_token(data)); 
export const logout =createAsyncThunk('logout', async token => destroy_access_token(token));

const authSlice =createSlice({

    name: 'auth',
    initialState, 

    extraReducers : builder =>{

        builder.addCase(login.fulfilled, (state, action) =>{
            const {error, user, accessToken} =action.payload;
            state.user =user || error;
            state.token =accessToken;
            state.isLoggedIn =accessToken? true: false;  
            if(!error){
                localStorage.setItem('current-user', JSON.stringify(state))
            }
        }); 

        builder.addCase(logout.fulfilled, (state, action) =>{
            const {status} =action.payload; 
            if(status ===axios.HttpStatusCode.NoContent){
                state.isLoggedIn =false;
                state.token =null;
                state.user =null;
                localStorage.removeItem('current-user');
            }

        });
    }
});
 
export default authSlice.reducer;