import {createSlice} from '@reduxjs/toolkit';
import {DONE, IDLE} from '../../utils/constants';
import post from '../axios/post';  
import get from '../axios/get';  
import { HttpStatusCode } from 'axios';

const events ={
    value: [], 
    status: IDLE,
    error: null
}

export const eventSlice =createSlice({
    name: 'events',
    initialState: events,
    reducers: { 

        postEvent: (state, action) =>{  
            post('events', action.payload)
                .then(({statusCode, data: {new_event, message}}) =>{
                    if([HttpStatusCode.BadRequest, HttpStatusCode.Conflict, HttpStatusCode.InternalServerError].find(code => code === statusCode)) {
                        state.error =message;
                    }
                    else state.value =new_event;
                    state.status =DONE;  
                })
                .catch(({message}) => {
                    state.error =message;
                    console.log(message);
                }
                )
        },
        getEvents: async( state, action) =>{  
            try{  
                const {statusCode, data: {events, message}} =await get('events');
                if([HttpStatusCode.BadRequest, HttpStatusCode.Conflict, HttpStatusCode.InternalServerError].find(code => code === statusCode)) state.error =message;
                else state.value =events;
                state.status =DONE;   
            }
            catch({message}) {
                state.error =message;
            }
             
        }
    }
})

export const {postEvent, getEvents} =eventSlice.actions;
export default eventSlice.reducer;