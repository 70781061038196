import React from 'react' 

const ChatComponent = () => {
  return (
    <div style={styles.page}> 
        <main style={styles.grid}>
            <div style={styles.chatsList}>
                <h2 style={styles.chatTitle}>Chats</h2>
            </div>
            <div style={styles.mainContent}>
                <h3 style={styles.recipient}>Recipient Name</h3> 
                <div style={styles.chatArea}>
                    <textarea style={styles.textarea} placeholder ="Send Message"></textarea>
                    <button style={styles.button}>Send</button>
                </div>
                <div>
                    <div  style={{...styles.chat, ...{justifyContent: 'flex-start'}}}><p style={styles.chatContent}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, magni.</p></div>
                    <div  style={{...styles.chat, ...{justifyContent: 'flex-end'}}}><p style={styles.chatContent}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, magni.</p></div>
                </div>
            </div> 
        </main>
    </div>
  )
}

const styles ={
    page: {
        border: '1px solid rgba(0, 0, 0, .1)',
        padding: '1rem',
        borderRadius: '.25rem',
    },
    recipient: {
        textAlign: 'center',
        paddingBottom: '1rem',
        color: '#333',
    }, 
    grid: {
        display: 'grid',
        gridTemplateColumns: '30% auto',  
    },
    chatsList: { 
    },
    mainContent:{
        backgroundColor: 'rgba(0, 0, 0, .01)',
        height: '70vh',
        flex: 3,
        position: 'relative', 
        padding: '1rem',
    },
    chatArea: {
        width: '75%',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: '1rem',
        display: 'flex', 
        alignItems: 'center'
    },
    textarea: {
        display: 'block', 
        resize: 'none',
        borderRadius: '.5rem 0 0 .5rem',
        height: "60px",
        padding: '1rem',
        width: '90%', 
    },
    button: {
        textAlign: 'center',
        backgroundColor: 'teal',
        color: 'white',
        borderRadius: '0 .5rem .5rem 0',  
        height: '60px',
        width: '10%',
        display: 'block',
        cursor: 'pointer'
    },
    chat: {
        display: 'flex',
        paddingBottom: '.5rem',
    },
    chatContent: {
        padding: '1rem',
        backgroundColor: 'rgba(0, 0, 0, .02)',
        color: 'rgba(0, 0, 0, .6)',
        borderRadius: '.5rem',
        maxWidth: '48%'
    },
    chatTitle: {  
    }
}

export default ChatComponent;